import _export from "../internals/export";
import _arrayIteration from "../internals/array-iteration";
import _arrayMethodHasSpeciesSupport from "../internals/array-method-has-species-support";
var $ = _export;
var $filter = _arrayIteration.filter;
var arrayMethodHasSpeciesSupport = _arrayMethodHasSpeciesSupport;
var HAS_SPECIES_SUPPORT = arrayMethodHasSpeciesSupport("filter");

// `Array.prototype.filter` method
// https://tc39.es/ecma262/#sec-array.prototype.filter
// with adding support of @@species
$({
  target: "Array",
  proto: true,
  forced: !HAS_SPECIES_SUPPORT
}, {
  filter: function filter(callbackfn /* , thisArg */) {
    return $filter(this, callbackfn, arguments.length > 1 ? arguments[1] : undefined);
  }
});
export default {};